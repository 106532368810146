<template>
  <v-dialog
    v-model="showCancellation"
    width="500"
    persistent
    @keydown.esc="onClose"
  >
    <v-card>
      <v-card-title class="text-h5 bg-prosk-secondary white--text">
        <span class="headline"> {{ $t("cancellationReason") }}</span>
      </v-card-title>
      <v-card-text>
        <v-col cols="12">
          <v-select
            :items="cancellationItems"
            :label="$t('selectReasonCancellation')"
            item-text="reason"
            item-value="id"
            color="#59D79D"
            :hide-details="reasonCancellationError.length === 0"
            :error-count="reasonCancellationError.length"
            :error-messages="reasonCancellationError"
            v-model="reasonCancellation"
          ></v-select>
        </v-col>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <cancel-button :cancel="onClose" :message="$t('cancel')"/>
        <save-button :save="cancelJob" :loading="loading" :message="$t('save')"/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import SaveButton from '@/components/socialvue/buttons/SaveButton.vue';
import CancelButton from '@/components/socialvue/buttons/CancelButton.vue';
import {
    JOB_STATUS_CLIENT_REJECTED,
    JOB_STATUS_CLIENT_CANCEL,
    JOB_STATUS_PROSKER_CANCEL,
    USER_TYPE_CLIENT
} from '@/misc/constants';

export default {
  name: 'CancellationReasonDialog',
  components: { CancelButton, SaveButton },
  props: ['idCancel', 'onClose', 'showCancellation', 'isProsker', 'onAdded'],
  mixins: [errorDialog],
  data () {
    return {
      reasonCancellation: null,
      loading: false,
      cancellationItems: [],
      reasonCancellationError: []
    };
  },
  created () {
      this.getCancellationReasons();
      this.setImportedConstants();
  },
  methods: {
    getCancellationReasons () {
      const url = `${process.env.VUE_APP_BASE_URL}/api/job-cancellation-reasons?filters=type_user=${this.$options.USER_TYPE_CLIENT}`;
        axios.get(url).then((response) => {
            this.cancellationItems = response.data.data
        }).catch((error) => {
            this.showError(error);
        });
    },
    setImportedConstants () {
        this.$options.JOB_STATUS_CLIENT_CANCEL = JOB_STATUS_CLIENT_CANCEL;
        this.$options.JOB_STATUS_CLIENT_REJECTED = JOB_STATUS_CLIENT_REJECTED;
        this.$options.USER_TYPE_CLIENT = USER_TYPE_CLIENT;
        this.$options.JOB_STATUS_PROSKER_CANCEL = JOB_STATUS_PROSKER_CANCEL;
    },
    cancelJob () {
      this.reasonCancellationError = [];
      if (this.reasonCancellation) {
        this.loading = true;
        const url = `${process.env.VUE_APP_BASE_URL}/api/jobs/${this.idCancel}`;
        const data = {
          status: this.isProsker ? this.$options.JOB_STATUS_PROSKER_CANCEL : this.$options.JOB_STATUS_CLIENT_CANCEL,
          reason: this.reasonCancellation
        };
        axios
          .patch(url, data)
          .then(() => {
            this.onClose();
            this.onAdded();
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.showError(error);
          });
      } else {
        this.reasonCancellationError.push(
          this.$t('fieldReasonCancellationRequired')
        );
      }
    }
  }
};
</script>
