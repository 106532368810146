<template>
  <v-dialog
      v-model="showHelp"
      width="500"
      persistent
      @keydown.esc="localClose"
  >
    <v-card>
      <v-card-title class="text-h5 bg-prosk-secondary white--text">
        <span class="headline"> {{ $t("needSomeHelp") }}</span>
      </v-card-title>
      <v-card-text>
        <v-col cols="12">
          <v-text-field
              :label="$t('needSomeHelpWithThisJob') + ' ?'"
              required
              :error-count="helpError.length"
              :error-messages="helpError"
              :hide-details="helpError.length === 0"
              color="#59D79D"
              v-model="help"
          ></v-text-field>
        </v-col>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <cancel-button :cancel="localClose" :message="$t('cancel')"/>
        <save-button :save="isProsker ? proskerAskHelpJob : clientAskHelpJob" :loading="loading" :message="$t('continue')"/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import SaveButton from '@/components/socialvue/buttons/SaveButton.vue';
import CancelButton from '@/components/socialvue/buttons/CancelButton.vue';

export default {
  name: 'HelpDialog',
  components: { CancelButton, SaveButton },
  props: ['idJob', 'onClose', 'showHelp', 'isProsker', 'onAdded'],
  mixins: [errorDialog],
  data () {
    return {
      help: null,
      loading: false,
      helpError: []
    };
  },
  methods: {
    localClose () {
      this.onClose();
      this.help = null;
    },
    clientAskHelpJob () {
      this.helpError = [];
      if (this.help) {
        this.loading = true;
        const url = `${process.env.VUE_APP_BASE_URL}/api/jobs/${this.idJob}`;
        const data = {
          status: 110,
          reason_help: this.help
        };
        axios
            .patch(url, data)
            .then(() => {
              this.onClose();
              this.onAdded();
              this.loading = false;
              this.help = null;
            })
            .catch((error) => {
              this.loading = false;
              this.help = null;
              this.showError(error);
            });
      } else {
        this.helpError.push(
            this.$t('fieldHelpRequired')
        );
      }
    },
    proskerAskHelpJob () {
      this.helpError = [];
      if (this.help) {
        this.loading = true;
        const url = `${process.env.VUE_APP_BASE_URL}/api/jobs/${this.idJob}`;
        const data = {
          status: 100,
          reason_help: this.help
        };
        axios
            .patch(url, data)
            .then(() => {
              this.onClose();
              this.onAdded();
              this.loading = false;
              this.help = null;
            })
            .catch((error) => {
              this.loading = false;
              this.help = null;
              this.showError(error);
            });
      } else {
        this.helpError.push(
            this.$t('fieldHelpRequired')
        );
      }
    }
  }
};
</script>
