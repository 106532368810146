<template>
    <v-dialog persistent v-model="showReject" width="500">
        <v-card>
            <v-card-title class="text-h5 bg-prosk-secondary white--text" primary-title>{{$t("reject") }}</v-card-title>
            <v-card-text class="mt-5 pb-0">
                <span class="black--text">{{ $t("areYouSureReject") }}</span>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="onClose" outlined color="#59D79D">
                    {{ $t("cancel") }}
                </v-btn>
                <v-btn
                        @click="confirmReject"
                        :loading="loading"
                        color="red"
                        class="white--text"
                >
                    {{ $t("reject") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'RejectWarning',
    props: ['onClose', 'confirmReject', 'showReject', 'loading']
};
</script>
